import './TableSchema.css';
import bell from '../../assets/images/bell.svg'
import { motion } from 'framer-motion'
function TableSchema({ data }) {
    const TABLES = [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]

    return (
        <div className="table-schema">
            {TABLES.map((item, i) => {
                // console.log((data.find(city => city.table === i + 1)) ? (data.find(city => city.table === i + 1)).type : '')
                // console.log(data.filter(function (n) {
                //     return n.table == i + 1;
                // }).length > 0
                //     ?
                //     data.filter(function (n) {
                //         return n.table == i + 1;
                //     })
                //     : '')
                let barman = data?.filter(function (n) {
                    return n.type == 'barman';
                }).length > 0
                    ?
                    data?.filter(function (n) {
                        return n.type == 'barman';
                    })
                    : ''

                let barman2 = (barman ? barman.map((item) => {
                    return (
                        item.table === 777 ?
                            26
                            :
                            item.table === 185 ?
                                25
                                :
                                item.table
                    )
                }) : '')

                let hookah = data?.filter(function (n) {
                    return n.type == 'hookah';
                }).length > 0
                    ?
                    data?.filter(function (n) {
                        return n.type == 'hookah';
                    })
                    : ''

                let hook2 = (hookah ? hookah.map((item) => {
                    return (
                        item.table === 777 ?
                        26
                        :
                        item.table === 185 ?
                            25
                            :
                            item.table
                    )
                }) : '')

                let checkt = data?.filter(function (n) {
                    return n.type == 'check';
                }).length > 0
                    ?
                    data?.filter(function (n) {
                        return n.type == 'check';
                    })
                    : ''

                let check2 = (checkt ? checkt.map((item) => {
                    return (
                        item.table === 777 ?
                        26
                        :
                        item.table === 185 ?
                            25
                            :
                            item.table
                    )
                }) : '')
        
                return (
                    <div key={i} className='table__box' style={{ gridArea: `table${i + 1}`, color: data?.find(city => city.table === i + 1) ? '#DEB75D' : '' }}>
                        <div className='table__box__icons'>
                            {barman2.includes(i + 1) || hook2.includes(i + 1) || check2.includes(i + 1) ?
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt='' className='table__box__icons__img' src={bell}>
                                </motion.img>
                                : null}
                        </div>
                        {i + 1 === 25 ?
                            <p className='table__box__numbers' style={{ color: data?.find(city => city.table === i + 1) ? '#DEB75D' : '' }}>185</p>
                            :
                            i + 1 === 26 ?
                                <p className='table__box__numbers' style={{ color: data?.find(city => city.table === i + 1) ? '#DEB75D' : '' }}>777</p>
                                :
                                <p className='table__box__numbers' style={{ color: data?.find(city => city.table === i + 1) ? '#DEB75D' : '' }}>{i + 1}</p>}
                    </div>
                )
            })}
        </div>
    );
}

export default TableSchema;